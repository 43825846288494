import React from 'react';
// import { useHistory } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import styles from './claimsdb.module.scss';

const WORKBENCH_URL = `${process.env.REACT_APP_FACTBRIEF_CLAIMSDB_URL}?q=`;

export default function ClaimsDB() {
    // const history = useHistory();

    React.useEffect(() => {
        setTimeout(() => {
            document.title = "Gigafact Partner Console";
        }, 1000);
    }, []);

    const handleOpenParser = () => {
        window.open(WORKBENCH_URL + localStorage.getItem("repustar_token"), "_blank");
        // history.goBack();
    };

    return (
        <div className={styles.workbenchContainer}>
            <div className={styles.buttonContainer}>
                <button className={styles.parserButton} onClick={handleOpenParser} style={{display: "flex", gap: "20px"}}>
                    Proceed to Parser <ArrowRightOutlined />
                </button>
            </div>
        </div>
    );
}