import React, { useState, useEffect } from 'react';
import { Switch, Button, Card, Select, Input, message, Modal, Row, Form, Upload } from 'antd';
import { CopyOutlined, PlusOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import styles from './quizManagement.module.scss';
// import OrgBanner from '../OrgBanner/orgbanner';
import Utils from '../../utils/utils';
import Loading from "../../assets/images/loader.gif";
import S3 from 'react-aws-s3';
import quizImage from '../../assets/images/console/take_fact_brief_quiz.jpg'; //  the quiz image

const { Option } = Select;
const { TextArea } = Input;


const bucketName = 'gigafact-app-images';
const folderName = 'resources/quiz';

const s3Config = {
  bucketName: bucketName,
  dirName: folderName,
  region: 'us-east-2',
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
};

const QuizPromotionManager = ({orgDetails}) => {
  // const [quizEnabled, setQuizEnabled] = useState(false);
  const [ctaCards, setCTACards] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewCard, setPreviewCard] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [availableCTATypes, setAvailableCTATypes] = useState(['image', 'donation', 'newsletter']);
  const [imageLoading, setImageLoading] = useState(false);

  const [form] = Form.useForm();

  const uploadToS3 = async (file) => {
    const ReactS3Client = new S3(s3Config);
    const orgName = localStorage.getItem('org_name').replaceAll(" ", "_");
    const filename = `${orgName}_cta_image_${new Date().getTime()}.${file.name.split(".").reverse()[0]}`;

    setImageLoading(true);

    try {
      const data = await ReactS3Client.uploadFile(file, filename);
      setImageLoading(false);
      return data.location;
    } catch (err) {
      console.error('S3 upload error:', err);
      message.error('Failed to upload image');
      setImageLoading(false);
      return null;
    }
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = quizImage;
    link.download = 'take_fact_brief_quiz.jpg';
    link.click();
  };

  useEffect(() => {
    const fetchCTACards = async () => {
      setIsLoading(true);
      try {
        const api = Utils.getAPIDescription('quiz-get-cta-items');
        const response = await axios.post(api, {org_slug: orgDetails.slug});
        console.log('Response:', response.data);

        if (response.status === 200) {
          setCTACards([...response.data]);
          updateAvailableCTATypes([...response.data])
          //
          // setCurrentCard(null);
        }

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }

    };
    if (orgDetails){
      fetchCTACards();
    }
  }, [orgDetails])



  // const handleQuizToggle = (checked) => {
  //   if (window.confirm("Are you sure?"))
  //     setQuizEnabled(checked);
  // };

  const handleCTAChange = (value) => {
    setCurrentCard({
      type: value,
      message: '',
      image_url: null,
      link: '',
      secondary_message: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCard({ ...currentCard, [name]: value });
  };

  const handleImageChange = async (info) => {
    const file = info.file.originFileObj;
    const imageUrl = await uploadToS3(file);

    if (imageUrl) {
      setCurrentCard({
        ...currentCard,
        image_url: {
          name: file.name,
          url: imageUrl
        }
      });
      message.success(`${file.name} uploaded successfully`);
    }
  };

  const handleImageUpdate = async (info) => {
    const file = info.file.originFileObj;
    const imageUrl = await uploadToS3(file);

    if (imageUrl) {
      form.setFieldsValue({
        image_url: {
          name: file.name,
          url: imageUrl
        }
      });
      message.success(`${file.name} uploaded successfully`);
    }
  };

  const handleCopyQuizLink = () => {
    // Declare base_url with let to ensure it's scoped correctly
    let base_url = '';

    // Set the base URL based on the environment
    if (process.env.REACT_APP_ENV === 'PROD') {
      base_url = 'https://gigafact.org/fact-brief-quiz/'; // Correct URL for production
    } else {
      base_url = 'https://dev.gigafact.org/fact-brief-quiz/'; // Correct URL for development
    }

    // Construct the quiz link
    const quizOrg = `${base_url}${orgDetails?.slug || 'defaultOrg'}`; // Use a fallback if org_name is not defined

    // Copy to clipboard using the Clipboard API
    navigator.clipboard.writeText(quizOrg).then(() => {
      message.success('Quiz link copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      message.error('Failed to copy quiz link');
    });
  };

  const updateAvailableCTATypes = (cards) => {
    const usedTypes = cards.map(card => card.type);
    const availableTypes = ['image', 'donation', 'newsletter'].filter(type => !usedTypes.includes(type));
    setAvailableCTATypes(availableTypes);
  }

  const handleCreateCard = async () => {
    if (currentCard) {
      const payload = {
        path: Utils.getAPIDescription('quiz-save-cta'),
        body: {
          org_slug: orgDetails.slug,
          cta_item: {
            active: false,
            ...currentCard
          }
        }
      };

      try {
        const response = await axios.post(payload.path, payload.body);
        console.log('Response:', response.data);

        if (response.status === 200) {
          const newCard = { ...currentCard, id: Date.now(), enabled: false };
          setCTACards([...ctaCards, newCard]);
          updateAvailableCTATypes([...ctaCards, newCard]);
          setCurrentCard(null);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleEnableCard = async (type) => {
    try {
      const updatedCards = ctaCards.map(card => ({
        ...card,
        active: card.type === type
      }));

      // Update the cards in the state
      setCTACards(updatedCards);

      // Prepare the payload for the API call
      const payload = {
        path: Utils.getAPIDescription('set-cta-status'),
        body: {
          org_slug: orgDetails.slug,
          status: true,
          type: type
        },
        headers: {
          user_id: localStorage.getItem('user_id') // Assuming user_id is stored in localStorage
        }
      };

      // Make the API call
      const response = await axios.post(payload.path, payload.body, { headers: payload.headers });

      if (response.status === 200) {
        message.success('CTA card status updated successfully');
      } else {
        throw new Error('Failed to update CTA card status');
      }
    } catch (error) {
      console.error('Error updating CTA card status:', error);
      message.error('Failed to update CTA card status');

      // Revert the state change if the API call fails
      setCTACards(prevCards => prevCards.map(card => ({
        ...card,
        active: card.type !== type && card.active
      })));
    }
  };

  const handlePreviewCard = (card) => {
    setPreviewCard(card);
    setIsModalVisible(true);
  };

  const handleDeleteCard = (type) => {
    setCTACards(ctaCards.filter(card => card.type !== type));
  };

  const [selectedCard, setSelectedCard] = useState(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);


  const handleUpdateCard = (card) => {
    setSelectedCard(card);
    form.setFieldsValue({
      link: card.link,
      image_url: card.image_url
    });
    setIsUpdateModalVisible(true);
  };

  const handleUpdateModalOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedCard = {
        ...selectedCard,
        link: values.link,
        image_url: values.image_url
      };

      const response = await axios.post(Utils.getAPIDescription('quiz-update-cta'), {
        org_slug: orgDetails.slug,
        cta_item: {
          active: updatedCard.active,
          image_url: updatedCard.image_url || null,
          link: updatedCard.link,
          type: updatedCard.type,
          message: updatedCard.message,
          secondary_message: updatedCard.secondary_message
        }
      }, {
        headers: { user_id: localStorage.getItem('user_id') }
      });

      if (response.status === 200) {
        message.success('CTA card updated successfully');
        setCTACards(ctaCards.map(card =>
            card.type === selectedCard.type ? updatedCard : card
        ));
        setIsUpdateModalVisible(false);
      }
    } catch (error) {
      console.error('Error updating CTA card:', error);
      message.error('Failed to update CTA card');
    }
  };

  const handleUpdateModalCancel = () => {
    setIsUpdateModalVisible(false);
  };

  const renderCTACard = (card, showPreviewButton = true) => {
    return (
      <Card
        title={card.type.charAt(0).toUpperCase() + card.type.slice(1)}
        extra={showPreviewButton && <Button onClick={() => handlePreviewCard(card)} icon={<EyeOutlined />}>Preview</Button>}
        className={styles.ctaCard + ' cta_card'}
        key={`card_${card.type.charAt(0).toUpperCase()}`}
      >
        <div className={styles.cardContent}>
          {/*<p><strong>Message:</strong> {card.title}</p>*/}
          {/*<p><strong>Secondary Message:</strong> {card.message}</p>*/}
          {card.type === 'image' &&  <p><strong>Image:</strong> {card.image_url ? card.image_url.name : 'No image uploaded'}</p>}
          <p><strong>Link:</strong> {card.link}</p>
          {/*<p><strong>Button Text:</strong> {card.buttonText}</p>*/}
          {/*<p><strong>Secondary Message:</strong> {card.secondary_message}</p>*/}
        </div>
        <div className={styles.cardActions}>
          <Button 
            onClick={() => handleEnableCard(card.type)}
            type={card.active ? 'primary' : 'default'}
          >
            {card.active ? 'Enabled' : 'Enable'}
          </Button>
          <Button onClick={() => handleUpdateCard(card)} type="dashed" style={{backgroundColor: "gainsboro"}}>
            Update
          </Button>
          {/*{showPreviewButton && (*/}
          {/*  <Button onClick={() => handleUpdateCard(card.type)} type={"dashed"} style={{backgroundColor: "gainsboro"}}>Update</Button>*/}
          {/*)}*/}
        </div>
      </Card>
    );
  };

  const renderPreview = (cta) => {
    switch (cta.type) {
      case 'image':
        return (
            <div>
              <a href={cta.link} onClick={() => {}} target="_blank" rel="noopener noreferrer"
                 className={styles.ctaImageLink}>
                <img src={cta.image_url.url} alt="Call to Action" className={styles.ctaImage}/>
              </a>
            </div>
        );
      case 'newsletter':
        return (
            <>
              <h2 className={styles.ctaHeadline}>Stay informed with fact-first journalism.</h2>
              <p className={styles.ctaMessage}>
                Local newsrooms like ours deliver the trustworthy, unbiased reporting you rely on.
                Subscribe to our newsletter for the latest updates and in-depth stories that matter.
              </p>
              <p className={styles.ctaSubMessage}>Join our community of informed readers today.</p>
              <a href={cta.link}  onClick={() => {}} target="_blank" rel="noopener noreferrer"
                 className={styles.primaryCTA}>
                Sign up for {orgDetails.org_name}&apos;s newsletter
              </a>
            </>
        );
      case 'donation':
        return (
            <>
              <h2 className={styles.ctaHeadline}>Help support fact-first journalism.</h2>
              <p className={styles.ctaMessage}>
                Local newsrooms like ours provide the trustworthy, unbiased reporting our community
                depends on. Your donation ensures we can continue to uphold these standards.
              </p>
              <p className={styles.ctaSubMessage}>Join us in keeping the facts first.</p>
              <a href={cta.link} onClick={() => {}}
                 className={styles.primaryCTA}>
                Donate to {orgDetails.org_name}
              </a>
            </>
        );
      default:
        return null;
    }

  };



  return (
    <div>

      <div className={styles.quizPromotionManager}>
        <div className={styles.header}>
          <h2>Quiz</h2>
          <div className={styles.headerControls}>

            <Button type="primary" onClick={downloadImage} >
              Download Quiz Image
            </Button>

            <Button icon={<CopyOutlined />} onClick={handleCopyQuizLink} >
              Copy Quiz Link
            </Button>
            {/*<Switch checked={quizEnabled} onChange={handleQuizToggle} />*/}
          </div>
        </div>

        <div className={styles.promotions}>

          <h3>Promotions</h3>
          <p className={styles.description}>
            Promotions appear in the middle of the quiz and on the “View my results so far” page, giving you the ability to produce calls-to-action for the user that are regularly seen. Though you are able to create multiple promotional cards, <b>only one can be enabled at a time</b>.
          </p>
          <p className={styles.description}>
            To integrate the quiz into your site, please download the quiz image using the button above and add it below your Fact Briefs. Then, insert your unique quiz link (which can be copied using the “Copy Quiz Link” button) to the image.
          </p>
          {/*<p className={styles.instruction}>Create multiple CTA cards. Only one card can be enabled at a time as the mid-quiz promotional content.</p>*/}
          
          <div className={styles.createCard}>
            {availableCTATypes.length > 0 &&
              <Select
                  className={styles.ctaSelect}
                  onChange={handleCTAChange}
                  value={currentCard?.type}
                  placeholder="Add a CTA card"
              >
                {availableCTATypes.map(type => (
                    <Option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</Option>
                ))}
              </Select>

            }

            {currentCard && (
              <>
                {/*<Input */}
                {/*  name="message"*/}
                {/*  placeholder="Enter title" */}
                {/*  value={currentCard.message}*/}
                {/*  onChange={handleInputChange}*/}
                {/*  className={styles.input}*/}
                {/*/>*/}
                {/*<TextArea */}
                {/*  name="secondary_message"*/}
                {/*  placeholder="Enter message" */}
                {/*  value={currentCard.secondary_message}*/}
                {/*  onChange={handleInputChange}*/}
                {/*  className={styles.input}*/}
                {/*/>*/}
                {currentCard?.type === 'image' &&
                  <Upload
                    name="image"
                    listType="picture"
                    className={styles.uploadButton}
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleImageChange({ file: { ...file, status: 'done', originFileObj: file }, type: 'new' });
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>
                }
                {currentCard.image_url && <p>{currentCard.image_url.name}</p>}
                <Input 
                  name="link"
                  placeholder="Enter link" 
                  value={currentCard.link} 
                  onChange={handleInputChange}
                  className={styles.input}
                />
                <Button onClick={handleCreateCard} icon={<PlusOutlined />} type={"primary"}>Create Card</Button>
              </>
            )}
          </div>
        </div>

        <div className={styles.ctaCards}>
          <Row gutter={[16, 16]} style={{gap: "30px"}}>
            {isLoading ? <div className='text-center' style={{margin: '0 auto', padding: "10px 0"}}><img alt={"Loading"} src={Loading} width='60px' className={styles.loader}/></div> : ''}
            {ctaCards.map((card) => (
              <div key={card.id}>
                {renderCTACard(card)}
              </div>
            ))}
          </Row>
        </div>

        <Modal
          title="Card Preview"
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          width={600}
        >
          {previewCard && <div className={styles.ctaContainer}>{renderPreview(previewCard)}</div>}
        </Modal>

        <Modal
            title="Update CTA Card"
            visible={isUpdateModalVisible}
            onOk={handleUpdateModalOk}
            onCancel={() => setIsUpdateModalVisible(false)}
        >
          <Form form={form} layout="vertical">
            {selectedCard?.type === 'image' && (
                <Form.Item
                    name={['image_url', 'url']}
                    label="Image"
                    rules={[{ required: true, message: 'Please add image!' }]}
                >
                  <Upload
                      name="image"
                      listType="picture"
                      className={styles.uploadButton}
                      showUploadList={false}
                      beforeUpload={(file) => {
                        handleImageUpdate({ file: { ...file, status: 'done', originFileObj: file } });
                        return false;
                      }}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>
                </Form.Item>
            )}
            <Form.Item
                name="link"
                label="Link"
                rules={[{ required: true, message: 'Please input the link!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default QuizPromotionManager;