import { BrowserRouter, Switch, Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Dashboard from './components/dashboard/dashboard.js';
import Flags from './components/flags/flags.js';
// import Reviews from './components/reviews/reviews.js';
import FactBriefs from './components/factbriefs/factbriefs.js';
// import Workspace from './components/workspace/workspace.js';
import Login from './components/login/login.js';
import Utils from './utils/utils.js';
import { Amplify } from 'aws-amplify';
import "antd/dist/antd.css";
import EditProfile from './components/editprofile/editprofile';
import CreateReview from './components/createreview/createreview';
import ResetPassword from './components/resetpassword/resetpassword';
import ReviewDetails from './components/reviewDetails/reviewDetails';
// import Landing from './components/landing/landing';
import CreateFlag from './components/createflag/createflag';
import AddUser from './components/addUser/addUser';
import AddNewAuthor from './components/addnewauthor/addnewauthor';
import Suspension from './components/suspension/suspension.js';
import UpdateEditor from './components/updateeditor/updateeditor';
// import axios from 'axios';
import PinDashboars from './components/pindashboard/pindashboard';
import Network from './components/network/network'
import Users from './components/users/users.js'

//New Layout
import { SignIn } from './components/partner-console/authentication/signIn/sign-in'
import { ForgotPassword } from './components/partner-console/authentication/forgot-password/forgot-password'
import { Layout } from './components/partner-console/layout/layout'
import { Dashboard } from './components/partner-console/pages/dashboard/dashboard'
import CPFlags from './components/partner-console/pages/flags/flags'
import CPEditProfile from './components/partner-console/pages/edit-profile/edit-profile'
import CPAddUser from './components/partner-console/pages/add-user/add-user'
import CPUpdateEditor from './components/partner-console/pages/update-editor/update-editor'
import { Answer } from './components/partner-console/pages/answer/answer'
import CreateNewOrganization from './components/partner-console/pages/organization-management/create-new-organization/create-new-organization';
import { SignUp } from './components/partner-console/authentication/signUp/sign-up';
import CPFactBriefs from './components/partner-console/pages/fact-briefs/fact-briefs/factbriefs';
import CPCreateReview from './components/partner-console/pages/fact-briefs/create-fact-brief/createreview';
import CPAddNewAuthor from './components/partner-console/pages/addnewauthor/addnewauthor';
import * as Sentry from "@sentry/react";
import CPAddNewAudienceManager from "./components/partner-console/pages/add-new-audience-manager/add-new-audience-manager";
import FactBriefPreview from "./components/partner-console/pages/fact-briefs/preview/preview";
import FactcheckWorkbench from "./components/partner-console/pages/factcheck-workbench/factcheckWorkbench";
import QuizPage from "./components/partner-console/pages/quiz/quiz-admin";
import ImageWatermarker from "./components/partner-console/pages/watermark-image/watermark";
import ClaimsDB from "./components/partner-console/pages/claims-db/claimsdb";
import { StatsExplorer } from './components/stats/stats.js';
// import QuizPromotionManager from './components/quizManagement/quizManagement.js'
import QuizManagementTabs from "./components/partner-console/pages/quiz/quizAdminV2";
function App() {
	const [isLogin, setIsLogin] = useState(false);
	const [orgDetails, setOrgDetails] = useState(null);
	const publicPaths = ['/partnerconsole/login', '/partnerconsole/forgot-password', '/partnerconsole/signup'];


	Amplify.configure(Utils.awsConfig());



	const checkIsLogin = async (user) => {
		if (localStorage.getItem('repustar_token') || user?.login) {
			setIsLogin(true);
			const redirectPath = await Utils.getRedirectPath();
			// console.log(redirectPath);
			if (publicPaths.includes(window.location.pathname)) {
				if(sessionStorage.redirect_path){
					if (!sessionStorage.redirect_path.includes('/partnerconsole/'))
						window.location.href = redirectPath;
					else
						window.location.href = sessionStorage.redirect_path;
				} else {
					window.location.href = redirectPath;
				}
			}
			if (window.location.pathname !== '/partnerconsole/edit-profile') {
				checkIntitalSetup();
			}
		} else {
			setIsLogin(false);
			if (!publicPaths.includes(window.location.pathname)) {

				if (!window.location.pathname.includes('/partnerconsole'))
					sessionStorage.redirect_path = '/partnerconsole/home';
				else
					sessionStorage.redirect_path=window.location.href;
				window.location.href = '/partnerconsole/login';
			}
		}
	};

	const checkIntitalSetup = () => {
		if (localStorage.getItem('initial_setup')) {
			window.location.href = '/partnerconsole/edit-profile';
		}
	}

	useEffect(() => {
		if (localStorage.getItem('email')) {
			Sentry.setUser({ email: localStorage.getItem('email') });
		}
		else {
			Sentry.setUser(null);
		}

		const localStorageCallback = (e) => {
			checkIsLogin()

		};

		checkIsLogin();
		window.addEventListener('storage', (e) => localStorageCallback(e));

		return () => {
			window.removeEventListener('storage', localStorageCallback);
		}

	}, [checkIsLogin, isLogin]);

	const loginCallback = (user) => {
		// console.log(user)
		if (user?.login) {
			// console.log(user)
			checkIsLogin(user);
		}
	};

	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/dashboard" component={PinDashboars} />
					<Route exact path="/flags" component={Flags} />
					{/* <Route exact path="/reviews" component={Reviews} />*/}
					<Route exact path="/fact-briefs" component={FactBriefs} />
					<Route exact path="/create-fact-brief/" component={CreateReview} />
					<Route exact path="/create-fact-brief/:pageId" component={CreateReview} />
					<Route exact path="/edit-profile" component={EditProfile} />
					<Route exact path="/fact-brief/:pageId" component={ReviewDetails} />
					<Route exact path="/create-flag" component={CreateFlag} />
					<Route exact path="/password-reset" component={ResetPassword} />
					<Route exact path="/add-user" component={AddUser} />
					<Route exact path="/add-author" component={AddNewAuthor} />
					<Route exact path="/suspension" component={Suspension} />
					<Route exact path="/update-editor" component={UpdateEditor} />
					{/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
					{/* <Route exact path="/partnerconsole/login" component={SignIn} /> */}

					{/* <Route> */}
					{/* <Route path="/partnerconsole" component={Layout} /> */}

					<Route exact>
						<Layout isLogin={isLogin} setIsLogin={setIsLogin} orgDetails={orgDetails}>
							<Switch>
								<Route exact path="/partnerconsole/login" component={(props) => <SignIn loginCallback={loginCallback} {...props} />} />
								<Route exact path="/partnerconsole/forgot-password" component={ForgotPassword} />
								<Route exact path="/partnerconsole/tipline" component={Dashboard} />
								<Route exact path="/partnerconsole/flags" component={CPFlags} />
								<Route exact path="/partnerconsole/edit-profile" component={CPEditProfile} />
								<Route exact path="/partnerconsole/add-user" component={CPAddUser} />
								<Route exact path="/partnerconsole/add-author" component={CPAddNewAuthor} />
								<Route exact path="/partnerconsole/add-audience-manager" component={CPAddNewAudienceManager} />
								<Route exact path="/partnerconsole/update-editor" component={CPUpdateEditor} />
								<Route exact path="/partnerconsole/answer" component={Answer} />
								<Route exact path="/partnerconsole/fact-briefs" component={CPFactBriefs} />
								<Route exact path="/partnerconsole/quiz-admin" component={QuizPage} />
								<Route exact path="/partnerconsole/watermark-image" component={ImageWatermarker} />
								<Route exact path="/partnerconsole/factcheck-workbench" component={FactcheckWorkbench} />
								<Route exact path="/partnerconsole/parser" component={ClaimsDB} />
								<Route exact path="/partnerconsole/fact-brief/:pageId" component={FactBriefPreview} />
								<Route exact path="/partnerconsole/create-fact-brief/" component={CPCreateReview} />
								<Route exact path="/partnerconsole/create-fact-brief/:pageId" component={CPCreateReview} />
								{/* <Route exact path="/partnerconsole/organiziation-list" component={OrganizationsList} /> */}
								<Route exact path="/partnerconsole/create-new-organization" component={CreateNewOrganization} />
								<Route exact path="/partnerconsole/signup" component={SignUp} />
								<Route exact path="/partnerconsole/network" component={Network} />
								<Route exact path="/partnerconsole/quiz-management" component={QuizManagementTabs} />
								<Route exact path="/partnerconsole/stats" component={StatsExplorer} />
								<Route exact path="/partnerconsole/users/:str?" component={Users} />
							</Switch>
						</Layout>
					</Route>
				</Switch>
			</BrowserRouter>
		</div >
	);
}
// Sentry.withErrorBoundary(CPFlags, { fallback: <p>an error has occurred</p> });

export default App;
