import React, { useEffect, useState, useMemo } from 'react';
import { Menu, Tooltip } from 'antd';
import { useHistory, useLocation } from "react-router-dom";
import './sidebar.scss';
import useOrgStore from "../../store/orgsStore";

const SideBar = ({ isLogin, body }) => {
    const history = useHistory();
    const location = useLocation();
    const { orgDetails, fetchOrgDetails, isFetched } = useOrgStore();

    const [sideMenu, setSideMenu] = useState([]);

    const userType = useMemo(() => localStorage.getItem('user_types')?.split(",") || [], []);
    const org_id_present = useMemo(() => localStorage.getItem("org_id"), []);
    const isAudienceManager = useMemo(() => localStorage.getItem("is_audience_manager") === 'true', []);

    const isFactSparrowOrg = useMemo(() => {
        const env = process.env.REACT_APP_ENV;
        return (env === 'DEV' || env === 'PROD') && org_id_present === '3';
    }, [org_id_present]);

    const isAdmin = useMemo(() => userType.includes("org_admin"), [userType]);

    const hasFactbriefService = useMemo(() => {
        return orgDetails?.services?.includes("factbrief_service") || false;
    }, [orgDetails]);

    const hasParserAccess = useMemo(() => {
        if (!orgDetails?.services?.includes("claimsdb_service")) {
            return false;
        }
        return userType.includes("claimsdb_user") || userType.includes("claimsdb_contributor");
    }, [orgDetails, userType]);

    useEffect(() => {
        if (!isFetched) {
            fetchOrgDetails();
        }
    }, [fetchOrgDetails, isFetched]);

    useEffect(() => {
        if (orgDetails) {
            updateMenu();
        }
    }, [location.pathname, isFactSparrowOrg, isAdmin, orgDetails, userType, isAudienceManager]);

    const updateMenu = () => {
        const menuLists = [
            { label: "Flags", router: "/partnerconsole/flags", display: true, disabled: !hasFactbriefService },
            { label: "Fact Briefs", router: "/partnerconsole/fact-briefs", display: userType.includes("cp_contributor"), disabled: !hasFactbriefService },
            { label: "Fact Brief Workbench", router: "/partnerconsole/factcheck-workbench", display: true, disabled: !(orgDetails?.services?.includes("drafterai_service") && userType.includes("drafterai_user")) },
            { label: "Tipline", router: "/partnerconsole/tipline", display: true, disabled: !hasFactbriefService },
            { label: "Parser", router: "/partnerconsole/parser", display: true, disabled: !hasParserAccess },
            { label: "Edit Profile", router: "/partnerconsole/edit-profile", display: true, disabled: false },
            // { label: "Quiz", router: "/partnerconsole/quiz-admin", display: true, disabled: !(hasFactbriefService && (userType.includes("content_partner_admin") || userType.includes("org_admin") || isAudienceManager)) },
            { label: "Fact Brief Quiz", router: "/partnerconsole/quiz-management", display: true, disabled: !(hasFactbriefService && (userType.includes("content_partner_admin") || userType.includes("org_admin") || isAudienceManager))},
            { label: "Watermark Image", router: "/partnerconsole/watermark-image", display: (userType.includes("content_partner_admin") || userType.includes("org_admin")), disabled: false },

            { 
                label: "Admin", 
                router: "", 
                display: isFactSparrowOrg && isAdmin, 
                disabled: false, 
                children: [
                    { label: "New Org Setup", router: "/partnerconsole/create-new-organization", display: true, disabled: false },
                    { label: "Network", router: "/partnerconsole/network", display: true, disabled: false },
                    { label: "Statistics", router: "/partnerconsole/stats", display: true, disabled: false },
                ]
            },
        ];

        const updatedMenu = menuLists
            .filter(menu => menu.display)
            .map((menu, index) => ({
                ...menu,
                key: `menu_${index}`,
                isActive: menu.router === location.pathname,
                children: menu.children?.map((child, childIndex) => ({
                    ...child,
                    key: `menu_${index}_child_${childIndex}`,
                    isActive: child.router === location.pathname,
                }))
            }));

        setSideMenu(updatedMenu);
    };

    const routingHandler = (e) => {
        history.push(e.key);
    };

    const generateMenu = (menuList) => menuList.map(menu => 
        menu.children ? (
            <Menu.SubMenu key={menu.key} className='menu-sub-items' title={menu.label}>
                {generateMenu(menu.children)}
            </Menu.SubMenu>
        ) : (
            <Menu.Item key={menu.router} className={`menu-items ${menu.isActive ? 'active' : ''}`} disabled={menu.disabled} onClick={routingHandler}>
                {menu.disabled ? (
                    <Tooltip title="Access disabled. Please contact admin for access.">
                        {menu.label}
                    </Tooltip>
                ) : menu.label}
            </Menu.Item>
        )
    );

    return (
        <div className="sidebar-container">
            {(isLogin && location.pathname !== '/partnerconsole/login') && (
                <div className="menu-section">
                    <Menu className="menu" mode="inline">
                        {generateMenu(sideMenu)}
                    </Menu>
                </div>
            )}
            <div className="body-section">
                {body}
            </div>
        </div>
    );
}

export default SideBar;