
import React, { useEffect, useState, useRef } from 'react';
import './tipline-admin-table.scss';
import { Line } from "react-chartjs-2";
import { Avatar, Button, Space, Table, Tag, Tooltip, Modal, Select, DatePicker, Pagination } from 'antd';
import { LeftOutlined, RightCircleFilled, TwitterOutlined, FacebookOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import axios from 'axios';
import Utils from '../../../../utils/utils.js';
import TiplineInstallationGuide from '../tipline-installation-guide/tipline-installation-guide';
import FBLogo from '../../../../assets/images/logos/facebook.svg';
import TwitterLogo from '../../../../assets/images/logos/twitter.png';
import { CSVLink } from 'react-csv';
import moment from 'moment'

const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function TiplineAdminTable(props) {
    const [tableData, setTableData] = useState([]);
    const [showTiplineInstallation, setShowTiplineInstallation] = useState(false);
    const [tableFilters, setTableFilters] = useState({ st: undefined, et: undefined, status: '0', source: '0', sort: 'desc' });
    const [tableFilterData, setTableFilterData] = useState(null);
    const [exportData, setExportData] = useState(null);
    const csvLink = useRef()

    const timezone = moment.tz.guess();
    const isFactsparrowOrg = (process.env.REACT_APP_ENV === 'DEV' && localStorage.getItem('org_id') == '1022') || (process.env.REACT_APP_ENV === 'PROD' && localStorage.getItem('org_id') == '3138');
    let shareContent = `Now accepting tips!.%0A%0ATell us what you want fact-checked!%0A%0A`
    let tiplineShareUrl = `https://${process.env.REACT_APP_ENV === 'PROD' ? '' : "dev."}gigafact.org/tipline?org_id=${props.orgDetails?.page_id}%26utm_source=${props.orgDetails?.slug}`;
    tiplineShareUrl += `${isFactsparrowOrg ? '%26called_from=fact-sparrow' : ''}`
    let twitterShareUrl = `http://twitter.com/share?text=${shareContent}&url=${tiplineShareUrl}%26utm_medium=twitter`;
    let fbShareUrl = `https://www.facebook.com/sharer/sharer.php?t=${shareContent}&u=${tiplineShareUrl}%26utm_medium=facebook`;
    const downloadCSV = () => {
        let api = Utils.getAPIDescription('get_tipline_queries') + `?org_name=${props.orgDetails?.org_name}`;
        api += '&limit=10000';
        let payload = {
            st: tableFilters.st,
            et: tableFilters.et,
            status: tableFilters.status,
            source: tableFilters.source
        }
        axios.post(api, payload, { headers: { "timezone": timezone } }).then((res) => {
            if (res.data?.response) {
                let response = res.data?.response
                let headers = [
                    { label: "Organization Name", key: "org_name" },
                    { label: "Source", key: "event_source" },
                    { label: "Date", key: "date" },
                    { label: "Query", key: "user_query" },
                    { label: "Link Shared", key: "link_shared" },
                    { label: "Status", key: "status" },
                ];
                setExportData({ headers: headers, data: response })
                csvLink.current.link.click();
            }
        }).catch(err => {
            console.log(err)
        })
    }
    function isValidHttpUrl(s) {
        let url;
        try {
            url = new URL(s);
        } catch (e) { return false; }
        return /https?/.test(url.protocol);
    }
    const share_url = (type) => {
        switch (type) {
            case 'Twitter':
                window.open(twitterShareUrl, '_blank')
                break;
            case 'Facebook':
                window.open(fbShareUrl, '_blank')
                break;
            default:
                window.open(twitterShareUrl, '_blank')
                break;
        }
    }
    const setSorting = (e) => {
        setTableFilterData({ ...tableFilterData, page: 1 })
        setTableFilters({ ...tableFilters, sort: tableFilters.sort === 'asc' ? 'desc' : 'asc' })
    }
    const getTiplineQueries = () => {
        let api = Utils.getAPIDescription('get_tipline_queries') + `?org_name=${props.orgDetails?.org_name}`;
        api += tableFilterData ? '&limit=' + tableFilterData.limit : '';
        api += tableFilterData ? '&page=' + tableFilterData.page : '';
        let payload = {
            st: tableFilters.st,
            et: tableFilters.et,
            status: tableFilters.status,
            source: tableFilters.source,
            sort: tableFilters.sort
        }
        axios.post(api, payload).then((res) => {
            if (res.data?.response) {
                setTableData(res.data?.response)
                let temp = {
                    source: res.data?.filter_map.source_filters,
                    status: res.data?.filter_map.status_filters,
                    is_last_page: res.data?.is_last_page,
                    page: res.data?.page,
                    total_count: res.data?.total_count,
                    limit: tableFilterData ? tableFilterData.limit : 10
                }
                setTableFilterData(temp)
            }
        }).catch(err => {
            console.log(err)
        })
    };
    const openTiplinePage = () => {
        let url = new URL(decodeURIComponent(tiplineShareUrl));
        url.searchParams.set("utm_medium", 'partner_console')
        url.searchParams.set("utm_source", props.orgDetails.slug)
        window.open(`${url}`)
    }

    useEffect(() => {
        // getTiplineQueries()
    }, []);

    const filterData = (e, type) => {
        // setTableFilterData((tableFilterData) =>  ({ ...tableFilterData, page: 1 }))
        setTableFilterData({ ...tableFilterData, page: 1 })
        if (type === 'source') {
            setTableFilters({ ...tableFilters, source: e })
            // setTableFilters((tableFilters) => ({ ...tableFilters, source: e }))
        } else if (type === 'status') {
            setTableFilters({ ...tableFilters, status: e })
        } else if (type === 'date') {
            if (e) {
                setTableFilters({ ...tableFilters, st: e[0].valueOf(), et: e[1].valueOf() })
            } else {
                setTableFilters({ ...tableFilters, st: undefined, et: undefined })
            }
        }
    }
    const paginationSet = (page, pageSize) => {
        setTableFilterData((tableFilterData) => tableFilterData.page = page, tableFilterData.limit = pageSize)
        getTiplineQueries();
    }
    useEffect(() => {
        getTiplineQueries();
    }, [tableFilters]);




    return (<div className="tiplie-admin-container">
        <div className="top-header">
            <div className="back-btn">
                {/* <Button className='btn' shape="circle" icon={<LeftOutlined />} onClick={() => { props.setshowAdminTable(false) }} /> */}
            </div>
            <div className='d-flex justify-content-center align-items-center'>
                <div className="tipline-btn">
                    <Button className="tipline-page-btn" onClick={() => { openTiplinePage() }}>{`${props.orgDetails?.org_name} Tipline`} <RightCircleFilled /></Button>
                </div>
                <div className="installation-guide">
                    <Button type="text" className="installation-guide-btn" onClick={() => { setShowTiplineInstallation(true) }}>Installation Guide</Button>
                </div>
            </div>

            <div className="share-btns">

                <>
                    <Button type='text' shape="circle" className='h-auto w-auto' onClick={() => { share_url("Twitter") }}>
                        <img className="twitter-logo" src={TwitterLogo} width={36} height={36} />
                    </Button>
                    <Button type='text' shape="circle" className='h-auto w-auto' onClick={() => { share_url("Facebook") }}>
                        <img className="fb-logo" src={FBLogo} width={36} height={36} />
                    </Button>
                </>
            </div>
        </div>

        <div className="table-header">

        </div>
        <div className="body-content">
            <div className="table-content inline-block">

                <div className="table-header">
                        <div className='left-block'>Data</div>
                        <div className='right-block'>
                            <div className="source" id={"SourceDropdown"} style={{display:'flex',flexDirection:'column',gap:'5px'}}>
                                <span >Source:</span>
                                <Select getPopupContainer={(trigger) => trigger.parentElement} defaultValue="0" onChange={(e) => { filterData(e, 'source') }} style={{ width: 120 }}>
                                    {tableFilterData && tableFilterData.source && tableFilterData.source.map((source, i) => {
                                        return (<Option value={source.key}>{source.string}</Option>)
                                    })}
                                </Select>
                            </div>
                            <div className="status" style={{display:'flex',flexDirection:'column',gap:'5px'}}>
                                <span >Status:</span>
                                <Select getPopupContainer={(trigger) => trigger.parentElement} defaultValue="0" style={{ width: 120 }} onChange={(e) => { filterData(e, 'status') }}>
                                    {tableFilterData && tableFilterData.status && tableFilterData.status.map((status, i) => {
                                        return (<Option value={status.key}>{status.string}</Option>)
                                    })}
                                </Select>
                            </div>
                            <div className="date" style={{display:'flex',flexDirection:'column',gap:'5px'}}>
                                <span >Date Range:</span><RangePicker onChange={(e) => { filterData(e, 'date') }} />
                            </div>
                            <div className="export px-2 pl-4" >
                                {exportData && <CSVLink
                                    data={exportData.data}
                                    filename={`tipline_data_${moment().format('YYYYMMDDHHmmss')}.csv`}
                                    className="d-none"
                                    target="_blank"
                                    headers={exportData.headers}
                                    ref={csvLink}
                                >
                                    Export
                                </CSVLink>}
                                <Button onClick={downloadCSV}>Export</Button>
                            </div>
                        </div>
                    </div>
                    
                <Table dataSource={tableData} pagination={{
                    defaultCurrent: tableFilterData ? tableFilterData.page : 1,
                    defaultPageSize: tableFilterData ? tableFilterData.limit : 10,
                    total: tableFilterData ? tableFilterData.total_count : 10,
                    current: tableFilterData ? tableFilterData.page : 1,
                    onChange: paginationSet,
                }}
                    showSorterTooltip={false}


                >
                    <Column className='sourceWidth' title="Source" dataIndex="event_source" key={`${new Date().getTime()}_event_source`} />
                    <Column className='createdDateWidth' title={(<div onClick={setSorting} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}>
                        Created Date {tableFilters.sort == 'asc' ? <CaretDownOutlined /> : <CaretUpOutlined />}
                    </div>)}
                        dataIndex="date" key={`${new Date().getTime()}_date`} />
                    <Column className='fixed-width'
                        title="User Query" key={`${new Date().getTime()}_user_query`}
                        render={(_, record) => (
                            <Space size="middle">
                                <Tooltip title={record.user_query} placement="topLeft">
                                    {record.user_query}
                                </Tooltip>
                            </Space>
                        )}
                    />
                    <Column className='fixed-width'
                        title="URL"
                        key={`${new Date().getTime()}_link_shared`}
                        render={(_, record) => (
                            <Space size="middle">
                                <Tooltip title={record.link_shared} placement="topLeft">

                                    {isValidHttpUrl(record.link_shared) ? <a href={record.link_shared} target="_blank" rel="noopener noreferrer">{record.link_shared}</a> : record.link_shared}
                                </Tooltip>
                            </Space>
                        )}
                    />
                    <Column title="Status" dataIndex="status" key={`${new Date().getTime()}_status`} />
                </Table>
                {/* {tableFilterData && tableFilterData.page &&
                    <Pagination defaultCurrent={tableFilterData.page} total={tableFilterData.total_count} onChange={paginationSet} />} */}
            </div>

            <Modal centered={true} title={null} visible={showTiplineInstallation}
                onCancel={() => { setShowTiplineInstallation(false) }} footer={[
                    <Button key="back" onClick={() => { setShowTiplineInstallation(false) }} >
                        Close
                    </Button>
                ]} width={700}>

                <TiplineInstallationGuide orgDetails={props.orgDetails} />
            </Modal>
        </div>

    </div >)
}