import React, {useEffect, useState} from 'react';
import { Tabs } from 'antd';
import QuizPromotionManager from '../../../quizManagement/quizManagement';
import QuizAnalytics from '../../../quizManagement/quizAnalytics';
import styles from './quizAdminV2.module.scss';
import OrgBanner from "../../../OrgBanner/orgbanner";
import Utils from "../../../../utils/utils";
import axios from "axios";

const { TabPane } = Tabs;

const QuizManagementTabs = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [orgDetails, setOrgDetails] = useState(null);

    useEffect(() => {
        getOrgsInfo();

    }, []);

    const getOrgsInfo = () => {
        const api = `${Utils.getAPIDescription('get-org-details')}?org_id=${localStorage.getItem('org_id')}`;
        axios
            .get(api)
            .then((res) => {
                if (res.data?.data) {
                    setOrgDetails(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <div className={styles.quizManagementTabs}>
            <div className="org-tipline-admin">
                <OrgBanner orgdetails={orgDetails} />
            </div>
            <Tabs activeKey={activeTab} onChange={handleTabChange} type="card" size={"large"} style={{paddingTop: "15px"}}>
                <TabPane tab="Quiz Management" key="1">
                    <QuizPromotionManager orgDetails={orgDetails}/>
                </TabPane>
                <TabPane tab="Quiz Analytics" key="2">
                    <QuizAnalytics orgDetails={orgDetails}/>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default QuizManagementTabs;