import create from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import Utils from "../../../utils/utils";

const useOrgStore = create(
    persist(
        (set) => ({
            orgDetails: null,
            isFetched: false,
            fetchOrgDetails: async () => {
                try {
                    const api = Utils.getAPIDescription("get-org-details") + "?org_id=" + localStorage.getItem("org_id");
                    const response = await axios.get(api);
                    const data = response.data.data;
                    set({ orgDetails: data, isFetched: true });
                } catch (error) {
                    console.error('Failed to fetch org details:', error);
                }
            },
        }),
        {
            name: 'org-storage', // unique name
            getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
        }
    )
);

export default useOrgStore;