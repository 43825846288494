import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, DatePicker, Button, message, Table } from 'antd';
import { Doughnut } from "react-chartjs-2";
import axios from 'axios';
import moment from 'moment';
import styles from './quizAnalytics.module.scss';
import Utils from "../../utils/utils";


const { RangePicker } = DatePicker;

const QuizAnalytics = ({ orgDetails }) => {
    const [stats, setStats] = useState(null);
    const [dateRange, setDateRange] = useState([moment().subtract(7, 'days'), moment()]);
    const [loading, setLoading] = useState(false);

    const fetchStats = async () => {
        setLoading(true);
        try {
            const [startDate, endDate] = dateRange;
            const api = Utils.getAPIDescription('get-quiz-stats')
            const response = await axios.get(api, {
                params: {
                    org: orgDetails.slug,
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD')
                }
            });
            setStats(response.data.stats);
        } catch (error) {
            console.error('Error fetching quiz stats:', error);
            message.error('Failed to fetch quiz statistics');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(orgDetails)
        if (orgDetails?.slug)
            fetchStats();
    }, [orgDetails]);

    const handleDateChange = (dates) => {
        setDateRange(dates);
    };




    const chartData = {
        labels: ['Correct Answers', 'Incorrect Answers'],
        datasets: [
            {
                data: stats ? [stats.percentage_correct_answers, 100 - stats.percentage_correct_answers] : [],
                backgroundColor: ['#0088FE', '#FF8042'],
                hoverBackgroundColor: ['#0077E6', '#FF6E1F']
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    let label = data.labels[tooltipItem.index] || '';
                    if (label) {
                        label += ': ';
                    }
                    label += data.datasets[0].data[tooltipItem.index].toFixed(2) + '%';
                    return label;
                }
            }
        }
    };

    const locationColumns = [
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
    ];

    return (
        <div className={styles.quizAnalytics}>
            <div className={styles.datePickerContainer}>
                <RangePicker
                    value={dateRange}
                    onChange={handleDateChange}
                    className={styles.datePicker}
                />
                <Button type="primary" onClick={fetchStats} loading={loading}>
                    Update Statistics
                </Button>
            </div>



            {stats && (
    <Row gutter={[16, 16]}>
        {/* Row 1 */}
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Total Unique Sessions"
                    value={stats.total_quizzes_taken}
                    precision={0}
                />
            </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Total Questions Answered"
                    value={stats.total_questions}
                    precision={0}
                />
            </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Percent Correct Answers"
                    value={stats.percentage_correct_answers}
                    precision={2}
                    suffix="%"
                />
            </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Fact Brief Link Clicks"
                    value={stats.fact_brief_link_clicks}
                    precision={0}
                />
            </Card>
        </Col>

        {/* Row 2 */}
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Promotional Link Views"
                    value={stats.promotional_link_views}
                    precision={0}
                />
            </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Promotional Link Clicks"
                    value={stats.promotional_link_clicks}
                    precision={0}
                />
            </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Promotional Click Rate"
                    value={stats.percentage_promotional_clicks}
                    precision={2}
                    suffix="%"
                />
            </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
            <Card>
                <Statistic
                    title="Fact Briefs Viewed"
                    value={stats.percentage_brief_viewed}
                    precision={2}
                    suffix="%"
                />
            </Card>
        </Col>
        
        {/* Answer Distribution Chart */}
        <Col xs={24} sm={24} md={12}>
            <Card title="Answer Distribution">
                <div style={{ height: '300px' }}>
                    <Doughnut data={chartData} options={chartOptions} />
                </div>
            </Card>
        </Col>

        {/* Top Locations Table */}
        <Col xs={24}>
            <Card title="Top Locations">
                <Table
                    dataSource={stats.top_locations}
                    columns={locationColumns}
                    pagination={false}
                />
            </Card>
        </Col>
    </Row>
)}

        </div>
    );
};

export default QuizAnalytics;