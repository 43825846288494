import React, { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2";
import { Avatar, Button, Select } from "antd";
import { DashboardOutlined, ArrowRightOutlined } from "@ant-design/icons";
import TiplineAdminTable from "../tipline-admin-table/tipline-admin-table";
import "./dashboard.scss";
import axios from "axios";
import Utils from "../../../../utils/utils.js";
import moment from "moment-timezone";
import OrgBanner from "../../../OrgBanner/orgbanner.js"
import useOrgStore from "../../store/orgsStore";

export const Dashboard = (props) => {
  const [showAdminTable, setshowAdminTable] = useState(true);
  const [allFlagsData, setallFlagsData] = useState(null);
  const [myFlagsData, setmyFlagsData] = useState(null);
  const [chartFilters, setchartFilters] = useState({
    period: "13",
    chartMaxVal1: 100,
    stepSize1: 20,
    chartMaxVal2: 100,
    stepSize2: 20,
  });
  // const [chartOptions, setchartOptions] = useState({});
  const [allFlagChartOption, setAllFlagChartOption] = useState({
    chartMaxVal: 100,
    stepSize: 20,
  });
  const [myFlagChartOption, setMyFlagChartOption] = useState({
    chartMaxVal: 100,
    stepSize: 20,
  });

  const { Option } = Select;

  const timezone = moment.tz.guess();

  const allFlagsChart = useRef();
  const myFlagsChart = useRef();

  useEffect(() => {
    setTimeout(() => {
      document.title = "Gigafact Partner Console";
    }, 1000);
  }, []);

  const tempAllFlags = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
        borderColor: "#ED6E85",
        tension: 0,
      },
    ],
  };
  const tempMyFlags = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: false,
        borderColor: "#0071BC",
        tension: 0,
      },
    ],
  };
  let AllFlagsOptions = {
    maintainAspectRatio: false,
    responsive: true,
    // height: 300,
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            stepSize: allFlagChartOption.stepSize,
            steps: 10,
            max: allFlagChartOption.chartMaxVal,
          },
        },
      ],
    },
  };
  let MyFlagsOptions = {
    maintainAspectRatio: false,
    responsive: true,
    // height: 300,
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            stepSize: myFlagChartOption.stepSize,
            steps: 10,
            max: myFlagChartOption.chartMaxVal,
          },
        },
      ],
    },
  };

  const getallFlagChartData = () => {
    let api = `${Utils.getAPIDescription("get_flags_chart_data")}?num_weeks=${
      chartFilters.period
    }`;
    axios.get(api, { headers: { timezone: timezone } }).then((res) => {
      if (res.status == 200 && res.data && res.data.response) {
        let response = res.data.response;
        tempAllFlags.labels = response.flags_aggs.x_axis;
        tempAllFlags.datasets[0].data = response.flags_aggs.y_axis;
        tempAllFlags.datasets.borderColor = "#ED6E85";
        // options.scales.yAxes[0].ticks.max=response.flags_aggs.chart_config.max_scale
        // options.scales.yAxes[0].ticks.stepSize=response.flags_aggs.chart_config.tick_interval
        setAllFlagChartOption({
          chartMaxVal: response.flags_aggs.chart_config.max_scale,
          stepSize: response.flags_aggs.chart_config.tick_interval,
        });

        // setchartFilters({ ...chartFilters, stepSize1: response.flags_aggs.chart_config.tick_interval,chartMaxVal1:response.flags_aggs.chart_config.max_scale })
        setallFlagsData(tempAllFlags);
      } else {
        tempAllFlags.labels = [];
        tempAllFlags.datasets[0].data = [];
        setallFlagsData(tempAllFlags);
      }
    });
  };
  const getmyFlagChartData = () => {
    let api = Utils.getAPIDescription("get_flags_chart_data");

    api += `?flag_type=tipline_flag&org_id=${localStorage.getItem(
      "org_id"
    )}&num_weeks=${chartFilters.period}`;

    axios.get(api, { headers: { timezone: timezone } }).then((res) => {
      if (res.status == 200 && res.data && res.data.response) {
        let response = res.data.response;
        tempMyFlags.labels = response.flags_aggs.x_axis;
        tempMyFlags.datasets[0].data = response.flags_aggs.y_axis;
        tempMyFlags.datasets.borderColor = "#0071BC";
        setMyFlagChartOption({
          chartMaxVal: response.flags_aggs.chart_config.max_scale,
          stepSize: response.flags_aggs.chart_config.tick_interval,
        });
        setmyFlagsData(tempMyFlags);
      } else {
        tempMyFlags.labels = [];
        tempMyFlags.datasets[0].data = [];
        setmyFlagsData(tempMyFlags);
      }
    });
  };

  const { orgDetails, fetchOrgDetails, isFetched } = useOrgStore();

  useEffect(() => {
    if (!isFetched) {
      fetchOrgDetails();
    }
  }, [fetchOrgDetails, isFetched]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  let userTypes = localStorage.getItem("user_types") || [];

  useEffect(() => {
    getallFlagChartData();
    getmyFlagChartData();
    setIsFirstLoad(false);
  }, []);
  const periodUpdate = (val) => {
    setchartFilters({ ...chartFilters, period: val });
  };
  useEffect(() => {
    if (!isFirstLoad) {
      getallFlagChartData();
      getmyFlagChartData();
    }
  }, [chartFilters.period]);

  return (
    <>
      {showAdminTable ? (
        <TiplineAdminTable
          setshowAdminTable={setshowAdminTable}
          orgDetails={orgDetails}
        />
      ) : (
        <div className="dashboard-container">
          <div className="org-tipline-admin">
          <OrgBanner orgdetails={orgDetails} />
          <div className="admin-btn">
              {orgDetails && userTypes.includes("content_partner_admin") && (
                  <Button
                    className="tipline-admin-btn"
                    type={"primary"}
                    style={{fontSize: "16px", display: "flex", alignItems: "center", padding: "20px", fontWeight: "600"}}
                    onClick={() => {
                      setshowAdminTable(true);
                    }}
                  >
                    Tipline Admin <ArrowRightOutlined />
                  </Button>
                )}
            </div>
          </div>
          <div className="chart-controls">
            <div className="period-block">
              <div className="label">Select Time Period : </div>
              <div className="select-block">
                <Select
                  value={chartFilters.period}
                  style={{ width: 150 }}
                  onChange={periodUpdate}
                >
                  <Option value="4">Last 4 weeks</Option>
                  <Option value="13">Last 13 weeks</Option>
                  <Option value="26">Last 26 weeks</Option>
                  <Option value="52">Last 52 weeks</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="my-tipline-block">
            <div className="chart-sections">
              <div className="top-row">
                <div className="heading">My Tipline Flags</div>
                <div className="filter-block">{/* Date: */}</div>
              </div>
              <div className="chart-block">
                {myFlagsData && (
                  <Line
                    data={myFlagsData}
                    options={MyFlagsOptions}
                    height={300}
                    ref={myFlagsChart}
                  />
                )}
              </div>
            </div>
            <br />
            <div className="chart-sections">
              <div className="top-row">
                <div className="heading">All Flags</div>
                <div className="filter-block"></div>
              </div>
              <div className="chart-block">
                {allFlagsData && (
                  <Line
                    data={allFlagsData}
                    options={AllFlagsOptions}
                    height={300}
                    ref={allFlagsChart}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
